$(document).ready(function () {
    const scrollContainer = document.querySelectorAll('.homepage-timelines-block-wrapper');

    scrollContainer.forEach(function (container) {
        const tilesWrapper = container.querySelector('.homepage-timelines-block-container');
        const scrollBarWrapper = $(container).closest('.homepage-timelines-block').find('.homepage-timelines-block-scrollbar-wrapper');
        const scrollBar = scrollBarWrapper.find('.homepage-timelines-block-scrollbar');
        const timelineItems = container.querySelectorAll('.homepage-timelines-block-item-wrapper');

        timelineItems.forEach(function (timelineItem, index) {
            timelineItem.classList.add('item-' + (index + 1));
            if (index == timelineItems.length - 1) {
                timelineItem.classList.add('last-item');
            }
        });

        if (tilesWrapper.clientWidth < window.innerWidth) {
            container.classList.add('justify-content-center');
            scrollBarWrapper.addClass('d-none');
            return;
        }

        container.addEventListener('wheel', (evt) => {
            evt.preventDefault();
            container.scrollLeft += evt.deltaY;
        });

        $(container).on('scroll', evt => {
            const scrollPercentage = 100 * evt.target.scrollLeft / (evt.target.scrollWidth - evt.target.clientWidth);
            const scrollBarWrapperWidth = scrollBarWrapper.width();
            const scrollBarWidth = scrollBar.width();

            scrollBar.css('margin-left', `${scrollPercentage * ((scrollBarWrapperWidth - scrollBarWidth) / 100)}px`);
        });
    });
});
